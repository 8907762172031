import { defineComponent } from 'vue';
export default defineComponent({
    props: {
        roomid: {
            type: String,
            required: true
        },
        debug: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: 0
        }
    },
    data: () => ({
        holes: [
            { x: 10, y: 490, width: 200, height: 150 },
            { x: 10, y: 170, width: 200, height: 310 },
            { x: 10, y: 10, width: 200, height: 150 },
            { x: 220, y: 10, width: 410, height: 150 },
            { x: 220, y: 170, width: 200, height: 150 },
            { x: 220, y: 330, width: 200, height: 150 },
            { x: 220, y: 490, width: 200, height: 150 },
            { x: 430, y: 490, width: 200, height: 150 },
            { x: 430, y: 330, width: 200, height: 150 },
            { x: 430, y: 170, width: 200, height: 150 },
            { x: 640, y: 245, width: 410, height: 150 },
            { x: 640, y: 450, width: 200, height: 190 },
            { x: 850, y: 405, width: 0, height: 0 },
            { x: 850, y: 405, width: 0, height: 0 },
            { x: 850, y: 405, width: 200, height: 190 },
        ],
        config: null,
        status: null,
        ctx: null
    }),
    computed: {
        holesStatus() {
            var _a, _b;
            if (this.debug) {
                const sensor = (_a = this.status) === null || _a === void 0 ? void 0 : _a.sensors.find((sensor) => {
                    return sensor.type === 'spidersensor';
                });
                if (sensor === undefined) {
                    return [];
                }
                return JSON.parse(sensor.status).holes;
            }
            else {
                const holes = [];
                for (let i = 0; i < 16; ++i) {
                    holes.push({ color: '000000', inside_motion: false, laser: false, sides_touched: false });
                }
                const wrong_state = (_b = this.status) === null || _b === void 0 ? void 0 : _b.wrong_state_reason;
                if ((wrong_state === null || wrong_state === void 0 ? void 0 : wrong_state.type) === 'motion') {
                    holes[wrong_state.hole].inside_motion = true;
                }
                if ((wrong_state === null || wrong_state === void 0 ? void 0 : wrong_state.type) == 'touched') {
                    holes[wrong_state.hole].sides_touched = true;
                }
                return holes;
            }
        }
    },
    watch: {
        status: {
            handler() {
                console.log('Status changed');
                this.drawRectangle();
            },
            deep: true
        }
    },
    async mounted() {
        this.drawRectangle();
        await this.$store.direct.dispatch.rooms.subscribeStatus(this.roomid);
        await this.$store.direct.dispatch.rooms.subscribe(this.roomid);
        this.config = this.$store.direct.state.rooms.config[this.roomid];
        // @ts-ignore
        this.status = this.$store.direct.state.rooms.status[this.roomid];
        const canvas = this.$refs.canvas;
        this.ctx = canvas.getContext('2d');
    },
    beforeDestroy() {
        this.$store.direct.dispatch.rooms.unsubscribe(this.roomid);
        this.$store.direct.dispatch.rooms.unsubscribeStatus(this.roomid);
    },
    methods: {
        // eslint-disable-next-line max-lines-per-function
        drawRectangle() {
            let factor = this.debug ? 1 : 0.35;
            if (this.width !== 0)
                factor = this.width / 800;
            const fillColor = this.debug ? '#ffffff' : '#009fe3';
            // eslint-disable-next-line max-lines-per-function
            this.holes.forEach((hole, index) => {
                const status = this.holesStatus[index];
                if (status === undefined || this.ctx === null) {
                    return;
                }
                this.ctx.fillStyle = this.holesStatus[index].inside_motion ? '#880000' : fillColor;
                this.ctx.fillRect(hole.x * factor, hole.y * factor, hole.width * factor, hole.height * factor);
                // eslint-disable-next-line max-len
                const lineColor = (this.debug && this.holesStatus[index].color == '000000') ? this.holesStatus[index].color : '000000';
                this.ctx.strokeStyle = '#' + lineColor;
                this.ctx.lineWidth = 5 * factor;
                this.ctx.strokeRect(hole.x * factor, hole.y * factor, hole.width * factor, hole.height * factor);
                if (this.debug) {
                    this.ctx.fillStyle = 'black'; // or any color you want for the text
                    this.ctx.font = '16px Arial'; // customize the font size and style as needed
                    const textMetrics = this.ctx.measureText(`${index + 1}`);
                    const textX = hole.x * factor + (hole.width * factor - textMetrics.width * factor) / 2;
                    const textY = hole.y * factor + (hole.height * factor / 2) + (textMetrics.actualBoundingBoxAscent / 2);
                    this.ctx.fillText(`${index + 1}` +
                        " (LDR " + this.holesStatus[index].inside_motion_difference + ")", textX, textY);
                }
                if (this.holesStatus[index].laser) {
                    this.ctx.lineWidth = 3 * factor;
                    this.ctx.strokeStyle = '#FF0000';
                    this.ctx.beginPath();
                    this.ctx.moveTo(factor * hole.x + hole.width * factor / 2 - factor * 15, hole.y * factor);
                    this.ctx.lineTo(factor * hole.x + hole.width * factor / 2 - factor * 15, hole.y * factor + hole.height * factor);
                    this.ctx.stroke();
                }
                if (this.holesStatus[index].sides_touched) {
                    this.ctx.strokeStyle = '#FF0000';
                    this.ctx.lineWidth = 16 * factor;
                    this.ctx.strokeRect(hole.x * factor + 16 * factor, hole.y * factor + 16 * factor, hole.width * factor - 2 * 16 * factor, hole.height * factor - 2 * 16 * factor);
                }
            });
        }
    }
});
