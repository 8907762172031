import { defineComponent } from 'vue';
import { Pregame, Stoppedgame, Testgame, Postgame } from '@/components/Widgets/GameStates';
import { CodeInput, Timer, Level, FullscreenMessage, MessageBar, IntroductionSlide } from '@/components/Widgets';
import { Animation } from '@/Animation';
import './RoomKeypad.scss';
import useTranslations from '@/compositions/useTranslations';
export default defineComponent({
    name: 'KeypadRoom',
    components: {
        Pregame,
        Postgame,
        Stoppedgame,
        Testgame,
        Timer,
        Level,
        FullscreenMessage,
        MessageBar,
        CodeInput,
        IntroductionSlide
    },
    props: {
        roomid: {
            type: String,
            required: true
        }
    },
    setup: () => ({
        ...useTranslations()
    }),
    data: () => ({
        animation: Animation,
    }),
    computed: {
        gameMessage() {
            return this.translation('codeRoomChallenge') + '<br>';
        },
        gameState() {
            return this.$store.direct.state.rooms.gameState;
        },
        level() {
            return this.$store.direct.state.rooms.level + 1;
        },
        morseSound() {
            return this.$store.direct.state.rooms.morse_sound;
        },
        score() {
            return this.$store.direct.state.rooms.score;
        },
        clockrunning() {
            return this.$store.direct.state.rooms.clockRunning;
        },
        timeleft() {
            return this.$store.direct.state.rooms.runningTimer;
        },
        mistakesLeft() {
            return this.$store.direct.state.rooms.mistakesLeft;
        },
        input() {
            return this.$store.direct.state.rooms.currentInput;
        },
        maxLength() {
            return this.$store.direct.state.rooms.maxLengthInput;
        },
        ingameStatus() {
            return this.$store.direct.state.rooms.ingameStatus;
        },
        gamestate() {
            return this.$store.direct.state.rooms.gameState;
        },
        soundUrl() {
            if (this.roomid === 'coderoom')
                return require('@/assets/sound/morse.mp3');
            else
                return require('@/assets/sound/bedroom_music.mp3');
        }
    },
    watch: {
        level(newValue, oldValue) {
            if (oldValue !== newValue && this.gamestate === 'playing') {
                Animation.animateLevelChange(() => { });
                setTimeout(() => { Animation.animateLevel('.level .level_icon', () => { }); }, 3000);
            }
        }
    },
    methods: {
        onEvent(eventType, data) {
            console.log('NEW EVENT OF TYPE ' + eventType + ':', data);
            if (eventType === 'clock' && data.status === 'start') {
                new Audio(require('@/assets/sound/go.mp3')).play();
            }
            else if (eventType === 'score' && data.status === 'up') {
                new Audio(require('@/assets/sound/correct.mp3')).play();
            }
            else if (eventType === 'clock' && data.status === 'stopped') {
                new Audio(require('@/assets/sound/pauze.mp3')).play();
            }
            else if (eventType === 'keypressed') {
                new Audio(require('@/assets/sound/keyboard.mp3')).play();
                console.log(data);
            }
        }
    }
});
